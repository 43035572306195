<template>
  <div class="" id="app">
    <div id="menu">

      <b-navbar toggleable="lg" class="pb-3" right>

        <b-container>
          <b-navbar-toggle target="nav-collapse" class="ml-auto"></b-navbar-toggle>
          <b-navbar-brand href="#app" class="mr-5 d-none d-md-block d-lg-block">
            <img src="@/assets/images/nav-logo-awaken.webp" class="img-fluid" style="max-width: 180px;">
          </b-navbar-brand>
          <b-collapse id="nav-collapse" is-nav right>
            <b-navbar-nav class="ml-auto">
              <!-- <b-nav-item href="#about">Sobre</b-nav-item> -->
              <b-nav-item class="mx-2" href="#about">GOOGLE ADS</b-nav-item>
              <b-nav-item class="mx-2" href="#faq">FAQ</b-nav-item>
              <b-nav-item class="mx-2" href="#servicos">SERVIÇOS</b-nav-item>
              <!-- <b-nav-item class="mx-2" href="#faq">CERTIFICAÇÕES</b-nav-item> -->
              <b-nav-item class="mx-2" href="#contatos">CONTATO</b-nav-item>
              <!-- <b-nav-item href="#" disabled>Disabled</b-nav-item> -->
            </b-navbar-nav>
          </b-collapse>
        </b-container>

      </b-navbar>
    </div>
    <!-- <div id="menu">
      <b-container>
        <b-row>
          <b-col>
            {{lastScrollPosition}}
          </b-col>
        </b-row>
      </b-container>
    </div> -->
    <div id="top" class="container">
      <b-row>
        <b-col class="toptitle" sm="12" md="4" lg="4">
          <!-- <b-img :src="require('../assets/images/busca.webp')" class="img-fluid"></b-img> -->
          <img src="@/assets/images/logo-awaken.webp" alt="" class="img-fluid logo animate__animated animate__fadeIn">
          <h1 class="title mb-3">Conquiste mais clientes e venda mais!</h1>
          <p>
            Nós ajudamos a sua empresa a Conquistar Mais Clientes através de anúncios no Google.
          </p>
          <p style="margin-left: 5px">
            <img width="30px" src="@/assets/images/nvisit.webp" alt="" class="img-fluid mb-2 mt-1"> <span>Aumente o número de visitas em seu site.</span> <br>
            <img width="30px" src="@/assets/images/mcham.webp" alt="" class="img-fluid mb-2 mt-1"> <span>Receba mais chamadas de clientes.</span> <br>
            <img width="30px" src="@/assets/images/maisv.webp" alt="" class="img-fluid mb-2 mt-1"> <span>Tenha mais visitas em sua loja.</span>
          </p>
          <a href="https://api.whatsapp.com/send?phone=5541995378667&text=Ol%C3%A1,%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20seus%20servi%C3%A7os" target="_blank" class="animate__animated animate__bounceIn mt-3 mb-4"><i class="fab fa-whatsapp"></i> (41) 9.9537-8667</a>
        </b-col>
        <b-col sm="12" md="8" lg="8" class="d-none d-sm-block d-md-none d-lg-block d-xl-none d-xl-block" style="overflow: hidden; min-height: 700px">
            <Banner />
        </b-col>
        <b-col sm="12" md="8" lg="8" class="d-block d-sm-none">
            <b-img :src="require('./assets/images/bannerMobile.webp')" class="img-fluid" ></b-img>
        </b-col>
        
      </b-row>
    </div>
    <div id="about">
    <!-- {{position}} -->
      <div class="container">
        <b-row>
          <b-col sm="12">
            <h2 class="title centerText">Marketing Digital gerando vendas e resultados.</h2>
            <span class="subtitleLine mt-3"></span>
          </b-col>
          <!-- <b-col sm="12" md="8">
            <p>
              Nosso foco é o atendimento de micro e pequenas empresas que não possuem assessoria na área de marketing digital. Com a crescente utilização da internet as relações entre empresas e pessoas se tornaram próximas. Portanto é muito importante ter uma boa estratégia de marketing digital executada através das mídias digitais, para construir um bom relacionamento com seu público alvo com o objetivo de se tornarem clientes. Além disso essas estratégias auxiliam a realizar novos negócios e expandir a marca.
            </p>
            <p>
              Há muitas maneiras possíveis de usar o marketing digital para impulsionar o seu negócio, por isso a importância de ter profissionais capacitados para gerenciar essas possibilidades e atingir o melhor resultado para sua marca. Por isso nós da Awaken temos a proposta de ter o melhor custo benefício para sua empresa alcançar o melhor desempenho no ambiente digital.
            </p>
          </b-col> -->
        </b-row>
      </div>
    </div>

    <div id="ads">
      <div class="container">
        <b-row>
          <b-col>
            <div align="center">
              <div class="rounded animate__animated animate__fadeInUp">
                <img src="@/assets/images/lgads.webp" alt="" class="img-fluid"> <br>
              </div>
              <strong class="mb-4 f2m">Google ads</strong> <br><br>
              <p>
                É o maior e mais utilizado site de pesquisas no mundo, no Brasil não seria diferente, cerca de 90% das pessoas realizam suas pesquisas no Google. Ter um bom posicionamento nas pesquisas é muito importante pela grande visualização que se pode atingir, visto a credibilidade existente da indicação do Google para seus usuários. Ter uma agência que crie seus anúncios e amplie sua visibilidade em uma plataforma que gera visitantes relevantes, faz diferença para crescer no ambiente digital. Além de criar,  analisar resultados, criar novas estratégias para manter bons resultados.
              </p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="4" lg="2">
            <div align="center">
              <b-img id="analytics" :src="require('./assets/images/logo-analytics.webp')" class="img-fluid hoverSaturate"></b-img>
            </div>
          </b-col>
          <b-col sm="12" md="4" lg="2">
            <div align="center">
              <b-img id="tagManager" :src="require('./assets/images/logo-tag-manager.webp')" class="img-fluid hoverSaturate"></b-img>
            </div>
          </b-col>
          <b-col sm="12" md="4" lg="4">
            <div align="center">
              <b-img id="searchConsole" :src="require('./assets/images/logo-search.webp')" class="img-fluid hoverSaturate"></b-img>
            </div>
          </b-col>
          <!-- <b-col sm="12" md="4" lg="2">
            <div align="center">
              <b-img id="urlBuilder" :src="require('./assets/images/logo-url-builder.webp')" class="img-fluid hoverSaturate"></b-img>
            </div>
          </b-col> -->
          <b-col sm="12" md="6" lg="2">
            <div align="center">
              <b-img id="dataStudio" :src="require('./assets/images/logo-looker.webp')" class="img-fluid hoverSaturate"></b-img>
            </div>
          </b-col>
          <b-col sm="12" md="6" lg="2">
            <div align="center" id="Gmaps">
              <b-img  :src="require('./assets/images/logo-maps.webp')" class="img-fluid hoverSaturate"></b-img>
            </div>
          </b-col>          
        </b-row>

          <b-tooltip target="analytics">
            Google <strong>Analytics</strong>
          </b-tooltip>
          <b-tooltip target="tagManager">
            Google <strong>Tag Manager</strong>
          </b-tooltip>
          <b-tooltip target="searchConsole">
            Google <strong>Search Console</strong>
          </b-tooltip>
          <!-- <b-tooltip target="urlBuilder">
            Google <strong>URL Builder</strong>
          </b-tooltip> -->
          <b-tooltip target="dataStudio">
            Looker <strong>Stúdio</strong>
          </b-tooltip>
          <b-tooltip target="Gmaps">
            Google <strong>Maps</strong>
          </b-tooltip>        
        <b-row>
        
        </b-row>
      </div>
    </div>

    <div id="faq">
      <div class="container">
        <b-row>
          <b-col>
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <a block v-b-toggle.accordion-1>O que é o Google Ads?</a>
                </b-card-header>
                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      O Google Ads (que era conhecido como Google AdWords e Google AdWords Express) é uma solução de publicidade on-line que as empresas usam para promover os seus produtos e serviços na Pesquisa Google, no YouTube e em outros sites na Web. Ele também permite que as empresas escolham metas específicas para os anúncios, como gerar mais chamadas telefônicas ou visitas ao site. Com uma conta do Google Ads, os anunciantes podem personalizar os respectivos orçamentos e segmentação, e iniciar ou interromper a exibição de anúncios quando quiserem.
                    </b-card-text>
                    <!-- <b-card-text>{{ text }}</b-card-text> -->
                  </b-card-body>
                </b-collapse>
              </b-card>
              
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <a block v-b-toggle.p2>Como o Google Ads funciona?</a>
                </b-card-header>
                <b-collapse id="p2" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>O Google Ads exibe seu anúncio quando as pessoas pesquisam on-line os produtos ou serviços que você oferece. Com uma tecnologia inteligente, o Google Ads exibe seus anúncios aos clientes em potencial no momento certo, quando eles estão prontos para realizar uma ação. <br>
                    Comece selecionando sua meta, como atrair mais visitantes para o site ou gerar mais chamadas telefônicas para a empresa. <br>
                    Em seguida, selecione a área geográfica onde você quer exibir o anúncio: pode ser dentro de um pequeno raio de distância da empresa ou uma área muito mais ampla, como cidades, estados ou países inteiros. <br>
                    Por fim, crie seu anúncio e defina um orçamento mensal. Quando o anúncio for aprovado, ele será exibido sempre que usuários na área de segmentação pesquisarem um produto ou serviço como o seu. Você só paga se os usuários interagem com o anúncio, por exemplo, quando clicam nele ou ligam para a empresa.
                    </b-card-text>
                    <!-- <b-card-text>{{ text }}</b-card-text> -->
                  </b-card-body>
                </b-collapse>
              </b-card>
              
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <a block v-b-toggle.p3>Quais são os diferentes tipos de anúncio do Google Ads?</a>
                </b-card-header>
                <b-collapse id="p3" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      Existem três tipos básicos de anúncio do Google Ads: <br>
                      Campanhas da rede de pesquisa: geralmente exibem anúncios em forma de texto nas páginas de resultados da Pesquisa Google quando alguém faz uma busca por um produto ou serviço semelhante ao seu Campanhas da Rede de Display: normalmente mostram anúncios em forma de imagem em sites ou aplicativos que os clientes visitam Campanhas de vídeo: em geral, exibem anúncios em vídeo de 6 ou 15 segundos antes ou durante a reprodução do conteúdo do YouTube <br>
                      Fale conosco e saiba mais sobre os outros tipos de campanhas avançadas, incluindo campanhas do Shopping, universais para apps e muito mais.
                    </b-card-text>
                    <!-- <b-card-text>{{ text }}</b-card-text> -->
                  </b-card-body>
                </b-collapse>
              </b-card>
              
              <b-card no-body class="">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <a block v-b-toggle.p4>Quanto custa anunciar no Google Ads?</a>
                </b-card-header>
                <b-collapse id="p4" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      O custo do Google Ads é determinado pelas configurações do orçamento. O Google Ads funciona com praticamente qualquer orçamento. Você só é cobrado se os usuários interagem com o anúncio, por exemplo, quando clicam para visitar seu site ou ligar para a empresa. Com o Google Ads, não há requisito mínimo de gasto nem de duração de contrato. Você pode interromper a exibição dos anúncios quando quiser. O custo de um clique varia de acordo com alguns fatores.
                    </b-card-text>
                    <!-- <b-card-text>{{ text }}</b-card-text> -->
                  </b-card-body>
                </b-collapse>
              </b-card>
              
            </div>
          </b-col>
        </b-row>
      </div>
    </div>

    <div id="servicos">
      <div class="container pt8em">
        <b-row class="mb-5">
            <b-col sm="12" align="center">
                <h2 class="title centerText" style="color: #333">Nossos Serviços</h2>
                <span class="subtitleLine mt-3"></span>
            </b-col>
            <b-col sm="12">
              <b-row class="terceiros mt-4">
                <b-col sm="12" md="4">
                  <div logo align="center">
                    <b-img  :src="require('./assets/images/google-logo.webp')" class="img-fluid"></b-img>
                    <strong>Consultoria Google</strong> <br>
                    <i>
                      (Ads, Analytics, GTM, Search Console e Maps)
                    </i>
                    <p>
                      Diagnóstico do site e Consultoria 360 na conta.
                    </p>
                  </div>
                </b-col>
                <b-col sm="12" md="4">
                  <div logo align="center">
                    <b-img  :src="require('./assets/images/gmy_business.webp')" class="img-fluid"></b-img>
                    <strong>Google Meu Negócio</strong> <br>
                    <i>(Maps)</i>
                    <p>
                      Cadastro e Otimização do perfil.
                    </p>
                  </div>
                </b-col>
                <b-col sm="12" md="4">
                  <div logo align="center">
                    <b-img  :src="require('./assets/images/gmyb.webp')" class="img-fluid"></b-img>
                    <strong>Pacote Completo</strong> <br>
                    <i>(Google Ads e Web Analytics)</i>
                    <p>
                      Diagnóstico do site, Consultoria 360, Gestão da conta e Otimizações constantes.
                    </p>
                  </div>
                </b-col>
              </b-row>

            </b-col>

          </b-row>
      </div>
      <div class="container">
        <b-row class="mb-5">
            <b-col sm="12" align="center">
                <h2 class="title centerText" style="color: #333">Terceirizados</h2>
                <span class="subtitleLine mt-3"></span>
            </b-col>
            <b-col sm="12">
              <b-row class="terceiros mt-4">
                <b-col sm="12" md="4">
                  <div logo align="center">
                    <b-img  :src="require('./assets/images/design.webp')" class="img-fluid"></b-img>
                    <strong>Design</strong>
                    <p>
                      Identidade Visual, Logotipos, Materiais Gráficos e Embalagens.
                    </p>
                  </div>
                </b-col>
                <b-col sm="12" md="4">
                  <div logo align="center">
                    <b-img  :src="require('./assets/images/social-media.webp')" class="img-fluid"></b-img>
                    <strong>Mídias Sociais</strong>
                    <p>
                      Gerenciamento de Redes Sociais.
                    </p>
                  </div>
                </b-col>
                <b-col sm="12" md="4">
                  <div logo align="center">
                    <b-img  :src="require('./assets/images/web.webp')" class="img-fluid"></b-img>
                    <strong>Web Design</strong>
                    <p>
                      Criação e otimização de sites, Landing Pages, Ecommerce, Implantação de SSL e etc...
                    </p>
                  </div>
                </b-col>
              </b-row>

            </b-col>

          </b-row>
      </div>
    </div>

    <div id="depoimentos" class="mt-5">
      <div class="container">
        <b-row>
          <b-col sm="12">
            <h2 class="title centerText">Depoimentos</h2>
            <span class="subtitleLine mt-3"></span>
          </b-col>
          <b-col sm="12">
            <b-row>
              <b-col cols="12" md="4" class="googlebusiness">
                <b-img fluid :src="require('./assets/images/google.svg')"></b-img>
                <strong>5.0</strong>
                  <i class="fas fa-star mx-1"></i>
                  <i class="fas fa-star mx-1"></i>
                  <i class="fas fa-star mx-1"></i>
                  <i class="fas fa-star mx-1"></i>
                  <i class="fas fa-star mx-1"></i>
                <b>Excelente!</b>
                <p class="mb-4">
                  Nossa reputação no Google
                </p>
              </b-col>
              <b-col cols="12" md="8">
                <Carousel :navigationEnabled="true" :perPageCustom="[[480, 1], [768, 2]]" autoplay loop autoplayHoverPause>
                  <Slide v-for="depoimento in depoimentos" :key="depoimento.id" class="px-3">
                    <div class="depoimentoItem">
                      <p>
                        {{depoimento.txt}}
                      </p>
                      <hr>
                      <strong>{{depoimento.nome}}</strong>
                      <span>{{depoimento.cargo}} | {{depoimento.empresa}}</span>
                      <a v-show="depoimento.url" :href="depoimento.url" target="_blank" class="badge badge-primary badge-pill">acesse o site</a>
                    </div>
                  </Slide>
                </Carousel>
              </b-col>
            </b-row>
            <!-- <b-carousel id="carousel-fade" fade> -->
              <!-- <b-carousel-slide v-for="depoimento in depoimentos" :key="depoimento.url">
                <template #img>
                  <div class="depos">
                  <p>
                    {{depoimento.txt}}
                  </p>
                  <b>{{depoimento.nome}}</b>
                  <span>{{depoimento.cargo}} | {{depoimento.empresa}}</span>

                  <a :href="depoimento.url" target="_blank">{{depoimento.url}}</a>
                  </div>
                </template>
              </b-carousel-slide> -->
            <!-- </b-carousel> -->
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- <div id="certificacoes">
      <div class="container">
        <b-row>
          <b-col sm="12">
            <h2 class="title centerText">Certificações Oficiais Google</h2>
            <span class="subtitleLine mt-3"></span>
          </b-col>
        </b-row>
        <b-row class="mt-4" style="max-width: 780px; margin: 0 auto">
          <b-col v-for="item in certificacoes" :key="item.img" class="mb-4 mt-2" cols="12" sm="6">
            <a :href="item.url" class="flex" target="_blank">
              <div class="item">
                <b-img :src="item.img" fluid class="imgcert"></b-img>
              </div>
              <div class="item">
                <strong>{{item.titulo}}</strong>
                <p>
                  Obtido: {{item.recebido}} <br>
                  Expira: {{item.expira}}
                </p>
              </div>
            </a>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12" class="text-center">
            <a href="https://skillshop.exceedlms.com/profiles/8bb886c6125e44ee97da71c8b10c557b" target="_blank" class="viewAll">ver todas</a>
          </b-col>
        </b-row>
      </div>
    </div> -->
    
    
    <div id="contatos">
      <div class="container">
        <b-row>
          <b-col sm="12">
            <h2 class="title centerText">Fale Conosco</h2>
            <span class="subtitleLine mt-3"></span>
          </b-col>
        </b-row>
        <b-row class="mt-4 mb-3">
          <b-col sm="12" md="6">
            <div class="cont" align="center">
              <i class="fas fa-mobile-alt"></i>
              <strong>Telefone</strong>
              <span>+55 41 9.9537-8667</span>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="cont" align="center">
              <i class="fas fa-paper-plane"></i>
              <strong>E-mail</strong>
              <span>contato@awakenmkt.com.br</span>
            </div>
          </b-col>
        </b-row>
        <!-- <b-row class="mt-5">
          <b-col sm="12">
            <h2 class="title centerText">Instagram</h2>
            <span class="subtitleLine mt-3"></span>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col v-for="item in feed" :key="item.id" cols="6" md="4">
            <a :href="item.permalink" v-if="item.media_type == 'IMAGE' || item.media_type == 'CAROUSEL_ALBUM'" class="blockInsta mt-4" :style="'background: url(' + item.media_url + ')no-repeat center center'" target="_blank">
              <div class="overlay">
                <i class="fas fa-search lupa"></i>    
              </div>

            </a>
            <a :href="item.permalink" v-else class="blockInsta mt-4" :style="'background: url(' + item.thumbnail_url + ')no-repeat center center'">
              <i class="fas fa-video iconvideo"></i>
              <div class="overlay">
                
              </div>
            </a>
            
          </b-col>
        </b-row> -->
      </div>
    </div>

    <!-- <div id="parceiros">
      <div class="container">
        <b-row>
          <b-col sm="12">
            <h2 class="title centerText">Parceiros</h2>
            <span class="subtitleLine mt-3"></span>
          </b-col>
        </b-row>
        <b-row class="">
          <b-col sm="12" md="3" align="center">
            <a href="http://vetorart.com.br" target="_blank">
              <b-img  :src="require('./assets/images/logo-vetorart.webp')" class="img-fluid"></b-img>
            </a>
          </b-col>
          <b-col sm="12" md="3" align="center">
            <a href="https://www.altaiyzdesign.com.br/" target="_blank">
              <b-img  :src="require('./assets/images/altaiyz-design.webp')" class="img-fluid"></b-img>
            </a>
          </b-col>

          <b-col sm="12" md="3" align="center">
            <a href="https://rodrigowebdesigner.com/site/" target="_blank">
              <b-img  :src="require('./assets/images/rodrigo-web-designer.webp')" class="img-fluid" style="max-width: 90px"></b-img>
            </a>
          </b-col>
          <b-col sm="12" md="3" align="center">
            <a href="https://www.diegosimas.com.br/" target="_blank">
              <b-img  :src="require('./assets/images/Diego_Simas.webp')" class="img-fluid"></b-img>
            </a>
          </b-col>
        </b-row>
      </div>
    </div> -->

    <div class="lineCollor"></div>
    <footer>
      <div class="container">
        <b-row class="lineFooter">
          <b-col class="siganos">
            <strong>Siga-nos</strong> 
            <a href="https://www.linkedin.com/company/awaken-marketing-digital" target="_blank"> <i class="fab fa-linkedin-in"></i> </a>
            <a href="https://www.facebook.com/awakenmarketingdigital" target="_blank"> <i class="fab fa-facebook-square"></i> </a>
            <a href="https://www.instagram.com/awakenmarketing/" target="_blank"> <i class="fab fa-instagram"></i> </a>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="5" class="assinatura">
            Awaken 2021 © Todos os direitos reservados <br>
            <span>
            Desenvolvido por <a href="https://vetorart.com.br" target="_blank">VetorArt</a>
            </span>
          </b-col>
          <b-col sm="12" md="7">
            <b-img :src="require('@/assets/images/logo-footer.webp')" class="img-fluid right" style="max-height: 60px; float: right; margin-top: -5px"></b-img>
          </b-col>
        </b-row>
      </div>
    </footer>

    <a href="#app" v-b-tooltip.hover.right="'Topo'" id="btnTop" class="button-top d-none d-sm-block" title="top" style="bottom: 10px;"><i class="fa fa-angle-up" aria-hidden="true"></i></a>

    
    <!-- <router-view></router-view> -->

    <!-- <a href="https://api.whatsapp.com/send?phone=5541995378667&text=Ol%C3%A1,%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20seus%20servi%C3%A7os" target="_blank" class="whatsapp"></a> -->
  </div>
</template>

<script>
import Banner from '@/components/BannerAnimado'
import depo from '@/dados/depoimentos.js'
import cert from '@/dados/certificacoes.js'
import axios from "axios"
import { Carousel, Slide } from 'vue-carousel';
// import Default from './components/template/Default.vue'

export default {
  name: 'App',
  components: {
    Carousel,
    Slide,
    Banner
  },
  data() {
    return{
      depoimentos: depo,
      certificacoes: cert,
      lastScrollPosition: 0,
      scrollOffset: 50,
      feed: []
    }
  },
  mounted() {
    this.instaFeed()
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.onScroll)
    document.getElementById("menu").style.top = "-100%";
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods:{
    onScroll() {
      if (window.pageYOffset < 0) {
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < this.scrollOffset) {
        return
      }
      this.showHeader = window.pageYOffset < this.lastScrollPosition
      this.lastScrollPosition = window.pageYOffset
      if(this.lastScrollPosition > 50){
        document.getElementById("menu").style.top = "0";
      } else {
        document.getElementById("menu").style.top = "-100%";
      }
    },
    instaFeed(){
      const token = "IGQVJXYnQ2M3FEQnJuR2lkaFNtaFRoY3Bka1RCZA1JXdVhWTkRhamM2NEtMSUR4MTBCVTRieFAycnZAoczMwTXVidVQtSm1LYW0xNThQZAndkTHB4T19vbm92VmdTUjFqZAjVKYnhRLXB0eU40SWhYUExoVwZDZD";
      const url = "https://graph.instagram.com/me/media?access_token="+token+"&fields=media_url,media_type,caption,permalink,thumbnail_url,timestamp,is_shared_to_feed,id,username&limit=6";

      axios.get(url).then((res) => {
        this.feed = res.data.data
      })
        
    }
  },
  created(){
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;
      if (prevScrollpos > currentScrollPos) {
        document.getElementById("btnTop").style.transform = "translateY(0)";
      } else {
        document.getElementById("btnTop").style.transform = "translateY(-100%)";
      }
      prevScrollpos = currentScrollPos;
    }

  }
}
</script>

<style lang="scss'" src="./assets/scss/_default.scss" />
