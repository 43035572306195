<template>
    <div class="engloba">
        <div class="">
            <b-img id="camila" class="animate__animated animate__fadeInUp img-fluid" :src="require('../assets/images/camila.webp')"></b-img>
            <b-img id="joao" class="animate__animated animate__fadeIn img-fluid" :src="require('../assets/images/joao.webp')"></b-img>
            <div id="typing" class="line-1 anim-typewriter"><span><strong>João</strong> é dono de uma cafeteria no centro.</span></div>
            <div id="busca" class="animate__animated animate__bounceIn">
                <b-img :src="require('../assets/images/busca.webp')" class="img-fluid"></b-img>
                <div id="searching" class="line-1 anim-typewriter2"><span>Cafeteria no centro</span></div>
                <div id="resultadobusca" class="animate__animated animate__fadeInDown">
                    <b-img :src="require('../assets/images/resultado-busca.webp')" class="img-fluid"></b-img>
                </div>
            </div>            
        </div>
        <!-- <div class="d-block d-sm-none">
            <b-img :src="require('../assets/images/bannerMobile.png')" class="img-fluid" ></b-img>
        </div> -->
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

.engloba{
    position: absolute;
    width: 100%;
    max-width: 700px !important;
    right: 0;
    // height: auto;
    // padding: 20px;
    // display: table;
}

#camila{
    position: absolute;
    right: 3em;
    top: 18em;
}
#joao{
    position: inherit;
    right: 25em;
    margin-top: 2em;
}
#typing{
    float: right;
    position: relative;
    left: 0;
    margin-top: 7em;
}
#busca{
    position: absolute;
    right: 18em;
    top: 22em;
    animation-delay: 3.6s;
    #searching{
        position: absolute;
        top: 2.2em;
        margin-left: 2em;
        animation-delay: 4.3s;
    }
}
#resultadobusca{
    position: relative;
    top: 0;
    right: 0;
    animation-delay: 6.7s;
    z-index: 0;
}

.line-1{
    position: relative;
    // left: 0;    
    // top: 50%;  
    width: 18em;
    // margin: 0 auto;
    border-right: 2px solid orange;
    // font-size: 180%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);    
}

/* Animation */
.anim-typewriter{
  animation: typewriter 2s steps(44) 1s 1 both running,
             blinkTextCursor 500ms steps(40) infinite normal;
}
.anim-typewriter2{
  animation: typewriter2 2s steps(44) 1s 1 both running,
             blinkTextCursor 500ms steps(40) infinite normal;
}
@keyframes typewriter{
  from{width: 0; left: -21em;}
  to{width: 18em; left: -3em;}
}
@keyframes typewriter2{
  from{width: 0; left: 0;}
  to{width: 8.8em; left: 0;}
}
@keyframes blinkTextCursor{
  0%{border-right-color: orange;}
  100%{border-right-color: transparent;}
//   100%{border: #000 !important;}
}


</style>