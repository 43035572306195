export default [
{
    // img: "https://placekitten.com/300/300",
    id: 1,
    // url: "https://alomudancascuritiba.com/site/",
    nome: "Mike Barbosa",
    cargo: "Proprietário",
    empresa: "Alô Mudanças Curitiba",
    txt: "Deixei de me sobrecarregar com o marketing da empresa e agora só me preocupo em atender todos os novos clientes, o volume de orçamentos aumentou quase que instantaneamente.Os meus anúncios agora têm muito mais direcionamento ao público alvo, com certeza vale o investimento ter alguém que entende cuidando do marketing.Resolveu meus problemas com anúncios, não me preocupo mais em procurar clientes. Consegui um retorno de investimento muito eficiente e não tenho mais preocupações com a visibilidade da empresa.",
},
{
    // img: "https://placekitten.com/300/300",
    id: 2,
    url: "https://psicologa-renata-chibeloski.negocio.site/",
    nome: "Renata Chibeloski",
    cargo: "Psicológa",
    empresa: "Psicoterapia e Avaliação Psicológica",
    txt: "Excelente profissional, dedicado e atencioso, me ajudou muito! Super recomendo.",
},
{
    // img: "https://placekitten.com/300/300",
    id: 3,
    url: "https://clinicadetecta.com.br/",
    nome: "Cristiane Pineroli Bochnia",
    cargo: "Proprietária",
    empresa: "Clínica Detecta",
    txt: "Profissional atencioso, educado e prestativo. Com sua ajuda e sugestões conseguimos planejar investimento de nosso marketing digital. Super recomendo!",
}
]